import {Heading, Text, TextLink} from '@smart/web-components';
import React from 'react';
import {MarketConfigContext} from '../../context/MarketConfigContext';
import './MarketAvailable.scss';

function MarketAvailable() {
  const content = React.useContext(MarketConfigContext).rootPage;

  return (
    <div className="market-available">
      <div className="market-available__heading_wrapper">
        <Heading id="heading-id" tag="h1" variant="headline-functional-100">
          {content.headline}
        </Heading>
      </div>
      <div className="market-available__bodytext_wrapper">
        <div className="market-available__warning_wrapper">
          <Text variant="paragraph-100">{content.text}</Text>
        </div>
      </div>
      <div className="market-available__button_wrapper">
        <TextLink
          className="market-available__button"
          variant="button-primary"
          href={content.ctaHref}
          linkText={content.ctaLabel}
        ></TextLink>
      </div>
      {content.changeHref && (
        <div>
          <TextLink
            variant="button-ghost"
            className="market-available__button-ghost"
            href={content.changeHref}
            linkText={content.changeLabel ? content.changeLabel : ``}
          ></TextLink>
        </div>
      )}
    </div>
  );
}

export default MarketAvailable;

// global styles
import '@smart/web-components/dist/assets/fonts/fonts.css';
import '@smart/web-components/dist/foundation.css';
import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import './app.scss';
import {
  MarketConfigContext,
  MarketConfigProvider,
} from './context/MarketConfigContext';
import ChangeSmartId from './pages/ChangeSmartId/ChangeSmartId';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Root from './pages/Root/Root';

export default function App() {
  useEffect(() => {
    document.body.classList.add(`light-theme`);
  });

  const helmetDefault = {
    titleTemplate: `%s - smart ID`,
    defaultTitle: `smart ID`,
    description: `smart ID`,
  };

  void import(`./utils/datadog-rum`).then(({setupDatadogBrowserRum}) => {
    setupDatadogBrowserRum();
  });

  return (
    <MarketConfigProvider>
      <BrowserRouter basename="/">
        <MarketConfigContext.Consumer>
          {(value) => (
            <Helmet
              titleTemplate={helmetDefault.titleTemplate}
              defaultTitle={helmetDefault.defaultTitle}
              htmlAttributes={{lang: `${value.language}-${value.market}`}}
            >
              <meta name="description" content={helmetDefault.description} />
              <script></script>
            </Helmet>
          )}
        </MarketConfigContext.Consumer>
        <Routes>
          <Route path="/" element={<Root />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/change-smart-id" element={<ChangeSmartId />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </MarketConfigProvider>
  );
}

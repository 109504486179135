// core packages
import React from 'react';
import {Helmet} from 'react-helmet-async';
import {WithLayout} from '../../components/Layout/WithLayout';
import NoMarket from '../../components/NoMarket/NoMarket';
import ScreenLoader from '../../components/ScreenLoader/ScreenLoader';
import {MarketConfigContext} from '../../context/MarketConfigContext';

const getRegisterComponent = (market: string) => {
  if (market === `UNKNOWN`) {
    return WithLayout(<NoMarket />);
  }

  return WithLayout(
    <ScreenLoader
      screenSet="smart-RegistrationLogin"
      startScreen="gigya-register-screen"
    />,
  );
};

export const Register = () => {
  const marketConfig = React.useContext(MarketConfigContext);

  const RegisterComponent = getRegisterComponent(marketConfig.market);

  return (
    <>
      <Helmet>
        <title>Registration</title>
        <meta name="description" content="Registration" />
      </Helmet>
      <div className="register">
        <RegisterComponent />
      </div>
    </>
  );
};

export default Register;

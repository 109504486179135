import {Heading, Text, TextLink} from '@smart/web-components';
import React from 'react';
import {MarketConfigContext} from '../../context/MarketConfigContext';
import './NoMarket.scss';

function NoMarket() {
  const marketConfig = React.useContext(MarketConfigContext);

  return (
    <div className="no-market">
      <div className="no-market__heading_wrapper">
        <Heading id="heading-id" tag="h1" variant="headline-functional-100">
          {marketConfig.rootPage.headline}
        </Heading>
      </div>
      <div className="no-market__bodytext_wrapper">
        <div className="no-market__warning_wrapper">
          <Text variant="paragraph-100">
            {marketConfig.rootPage.text} {marketConfig.rootPage.text2}
          </Text>
        </div>
      </div>
      <div className="no-market__button_wrapper">
        <TextLink
          className="no-market__button"
          variant="button-primary"
          href={marketConfig.rootPage.ctaHref}
          linkText={marketConfig.rootPage.ctaLabel}
        ></TextLink>
      </div>
    </div>
  );
}

export default NoMarket;

import {StrictMode} from 'react';
import * as React from 'react';
import {createRoot} from 'react-dom/client';
import {HelmetProvider} from 'react-helmet-async';
import App from './app';
import './index.scss';
import {waitForInteractive} from './utils/wait-for-interactive';

waitForInteractive().then(
  () => {
    const MOUNT_NODE = document.getElementById(`root`) as HTMLElement;

    const root = createRoot(MOUNT_NODE);
    root.render(
      <HelmetProvider>
        <StrictMode>
          <App />
        </StrictMode>
      </HelmetProvider>,
    );
  },
  () => {
    console.error(
      `react app could not be initialized, dom never became interactive`,
    );
  },
);

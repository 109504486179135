import {StrictMode} from 'react';
import * as React from 'react';
import {render} from 'react-dom';
import {HelmetProvider} from 'react-helmet-async';
import App from './app';
import './index.scss';
import {waitForInteractive} from './utils/wait-for-interactive';

waitForInteractive().then(
  () => {
    const MOUNT_NODE = document.getElementById(`root`) as HTMLElement;

    render(
      <HelmetProvider>
        <StrictMode>
          <App />
        </StrictMode>
      </HelmetProvider>,
      MOUNT_NODE,
    );
  },
  () => {
    console.error(
      `react app could not be initialized, dom never became interactive`,
    );
  },
);

import React from 'react';
import {BASE_CLASS} from '../../utils/configs';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import './Layout.scss';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({children}: LayoutProps) => (
  <div className={BASE_CLASS}>
    <Header />
    <div className={`${BASE_CLASS}__wrapper`}>
      <main className={`${BASE_CLASS}__content`}>{children}</main>
    </div>
    <Footer />
  </div>
);

export default Layout;

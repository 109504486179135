import {marketIdSchema} from '@smart/types';
import {jsonSafeParse} from '@smart/utils';
import type {PropsWithChildren} from 'react';
import React from 'react';
import type {AemContent} from '../lambda/aem/types';
import {fetchAem} from '../utils/api/fetch-aem';
import type {BooleanToggle} from '../utils/api/types';
import {sanitizeLogs} from '../utils/sanitize-logs';
import {MarketConfigContext} from './MarketConfigContext';

interface AemContentContext {
  data: AemContent | {};
  isLoading: boolean;
  isError: boolean;
}

export const AemContentContext = React.createContext<AemContentContext>({
  data: {},
  isLoading: false,
  isError: false,
});

export const AemContentProvider = ({
  children,
}: PropsWithChildren): React.ReactElement => {
  const [aemContent, setAemContent] = React.useState<AemContent | {}>({});
  const [isLoading, setLoading] = React.useState(true);
  const [isError, setError] = React.useState(false);

  const marketConfig = React.useContext(MarketConfigContext);

  React.useEffect(() => {
    const abortController = new AbortController();
    const marketId = marketIdSchema.safeParse(
      marketConfig.market.toLowerCase(),
    );

    if (!marketId.success) {
      if (process.env.ENV_NAME !== `prod`) {
        console.error({
          errorType: `INVALID_MARKET_ID`,
          message: `Invalid marketId: ${marketConfig.market}`,
        });
      }
      setLoading(false);
      return;
    }

    /**
     * NOTE: id.smart doesn't have preview support on infrastructure level
     * this is a tweak to allow fetching non-published AEM content
     * 'preview' should be set in localStorage with 'true' || 'false' value, if not set -> 'false' is used
     **/
    const preview = jsonSafeParse(
      localStorage.getItem(`preview`) || `false`,
    ) as BooleanToggle;

    const mockResponseEnabled = jsonSafeParse(
      localStorage.getItem(`mock-aem-bff`) || `true`,
    ) as BooleanToggle;

    fetchAem({
      marketId: marketId.data,
      mockResponseEnabled,
      preview,
      signal: abortController.signal,
    })
      .then((data) => {
        setAemContent(data);
      })
      .catch((e) => {
        if (!abortController.signal.aborted) {
          setError(true);
          if (process.env.ENV_NAME !== `prod`) {
            console.error({
              errorType: `AEM_FETCH_ERROR`,
              message: sanitizeLogs(e.message),
            });
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return () => abortController.abort();
  }, []);

  return (
    <AemContentContext.Provider value={{data: aemContent, isLoading, isError}}>
      {children}
    </AemContentContext.Provider>
  );
};

import {languageTagSchema} from '@smart/types';
import React, {useEffect, useState} from 'react';
import {sanitizeLogs} from '../utils/sanitize-logs';

export type MarketConfig = {
  language: string;
  market: string;
  title: string;
  errorCodes: ErrorCodeNotifications;
  rootPage: {
    headline: string;
    text: string;
    text2?: string;
    ctaLabel: string;
    ctaHref: string;
    changeLabel?: string;
    changeHref?: string;
  };
  logo: LabelLink;
  links: {
    [name: string]: LabelLink;
  };
  copyright?: string;
  footerLink?: LabelLink;
};

export type ErrorCodeNotifications = {
  [name: string]: ErrorCodeNotification;
};

export type ErrorCodeNotification = {
  code: string;
  variant: string;
  headline: string;
  text: string;
};

export type LabelLink = {
  href: string;
  label: string;
  hidden?: boolean;
};

export const MarketConfigContext = React.createContext<MarketConfig>(
  {} as MarketConfig,
);

const getActiveConfigKeyFromLocaleTag = (localeTag: string) => {
  const isValidLocale = languageTagSchema.safeParse(localeTag);
  const activeConfigKey = isValidLocale.success ? localeTag : `en-UNKNOWN`;
  const envName = process.env.ENV_NAME;

  if (!isValidLocale.success && envName !== `prod`) {
    console.error({
      errorType: `InvalidLocale`,
      localeTag: sanitizeLogs(localeTag),
      message: sanitizeLogs(isValidLocale.error.message),
    });
  }

  return activeConfigKey;
};

export const MarketConfigProvider = ({children}: any): React.ReactElement => {
  const [marketConfig, setMarketConfig] = useState<MarketConfig>();

  const language = localStorage.getItem(`language`);
  const market = localStorage.getItem(`market`);
  const localeTag = `${language}-${market}`;

  const activeConfigKey = getActiveConfigKeyFromLocaleTag(localeTag);

  useEffect(() => {
    fetch(
      `${process.env.BASE_URL}/assets/market-config/${activeConfigKey}.json`,
    )
      .then(async (response) => response.json())
      .then((config) => {
        setMarketConfig(config);
      })
      .catch((reason) => {
        console.error(`unable to fetch market config!`, reason);
      });
  }, [activeConfigKey]);

  return marketConfig ? (
    <MarketConfigContext.Provider value={marketConfig}>
      {children}
    </MarketConfigContext.Provider>
  ) : (
    <></>
  );
};

import {Spinner} from '@smart/react-components';
import clsx from 'clsx';
import type {PropsWithChildren} from 'react';
import React from 'react';
import {AemContentContext} from '../../context/AemContentContext';
import {BASE_CLASS} from '../../utils/configs';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import './Layout.scss';

const Layout = ({children}: PropsWithChildren) => {
  const aemContent = React.useContext(AemContentContext);
  const isLoading = aemContent.isLoading;
  return (
    <div className={BASE_CLASS}>
      <Header />
      <div className={`${BASE_CLASS}__wrapper`}>
        <main
          className={clsx(
            `${BASE_CLASS}__content${isLoading ? `--center` : ``}`,
          )}
        >
          <Spinner size={`xl`} loading={isLoading}>
            {children}
          </Spinner>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;

import React from 'react';
import ErrorCodeNotificationDisplay from '../ErrorCodeNotificationDisplay/ErrorCodeNotificationDisplay';
import Layout from './Layout';

export const WithLayout = (Component: JSX.Element) => () => {
  return (
    <Layout>
      <ErrorCodeNotificationDisplay />
      {Component}
    </Layout>
  );
};

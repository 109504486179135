import React, {useEffect} from 'react';
import {MarketConfigContext} from '../../context/MarketConfigContext';

/**
 * For detailed information regarding the OIDC login implementation -
 * https://help.sap.com/docs/SAP_CUSTOMER_DATA_CLOUD/8b8d6fffe113457094a17701f63e3d6a/4167d5cb70b21014bbc5a10ce4041860.html?locale=en-US
 */

/**
 * @interface
 * WindowWithGigya for Gigya scripts
 */
interface WindowWithGigya extends Window {
  gigya?: {
    thisScript: {lang: {langCode: string}};
    utils: {
      URL: {
        addParamsToURL: (url: string, options: unknown) => string;
      };
    };
    socialize: {
      addEventHandlers: (options: unknown) => void;
    };
    accounts: {
      showScreenSet: (options: unknown) => void;
    };
  };
}

interface ScreenLoaderProps {
  startScreen?: string;
  screenSet?: string;
}

/**
 * @returns JSX.Element LoginScreen
 */
const ScreenLoader: React.FunctionComponent<ScreenLoaderProps> = ({
  startScreen,
  screenSet,
}) => {
  const {language} = React.useContext(MarketConfigContext);

  useEffect(() => {
    const windowWithGigya: WindowWithGigya = window;

    /**
     * redirectToProxy as the name suggest redirects the url to the proxy page.
     * Depending on the CDC configurations, the proxy page either redirects the user to the consent page or back to the redirect url.
     * so addParamsToURL should have the path of the proxypage. ( proxy page is a must & its url is configured in CDC )
     */
    function redirectToProxy() {
      const url =
        windowWithGigya.gigya &&
        windowWithGigya.gigya?.utils?.URL?.addParamsToURL(`/proxy`, {
          mode: `afterLogin`,
          lang: language,
        });

      if (url) {
        window.location.href = url;
      }
    }

    windowWithGigya.gigya?.socialize?.addEventHandlers({
      onLogin() {
        redirectToProxy();
      },
    });

    /**
     * This functions gets the corresponding screenset from CDC & loads in the empty div container with id #container.
     */
    windowWithGigya.gigya?.accounts?.showScreenSet({
      screenSet,
      containerID: `container`,
      startScreen,
    });
  }, [startScreen]);

  return <div id="container" />;
};

export default ScreenLoader;

// core packages
import React from 'react';
import {Helmet} from 'react-helmet-async';
import Layout from '../../components/Layout/Layout';
import ScreenLoader from '../../components/ScreenLoader/ScreenLoader';

export const ResetPassword = () => {
  return (
    <>
      <Helmet>
        <title>Reset Password</title>
        <meta name="description" content="Reset Password" />
      </Helmet>
      <div className="reset-password">
        <Layout>
          <ScreenLoader
            screenSet="smart-RegistrationLogin"
            startScreen="gigya-reset-password-screen"
          />
        </Layout>
      </div>
    </>
  );
};

export default ResetPassword;

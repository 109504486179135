import React from 'react';
import {WithLayout} from '../../components/Layout/WithLayout';
import MarketAvailable from '../../components/MarketAvailable/MarketAvailable';
import NoMarket from '../../components/NoMarket/NoMarket';
import {MarketConfigContext} from '../../context/MarketConfigContext';

const getRootComponent = (market: string) => {
  if (market === `UNKNOWN`) {
    return WithLayout(<NoMarket />);
  }

  return WithLayout(<MarketAvailable />);
};

export const Root = () => {
  const marketConfig = React.useContext(MarketConfigContext);
  const RootComponent = getRootComponent(marketConfig.market);

  return <RootComponent />;
};

export default Root;

import {Logo} from '@smart/web-components';
import React from 'react';
import {MarketConfigContext} from '../../context/MarketConfigContext';
import styles from './Header.module.scss';

function Header() {
  const header = React.useContext(MarketConfigContext);

  return (
    <header className={styles[`header`]}>
      <a
        className={styles[`header__link`]}
        href={header.logo.href}
        aria-label={header.logo.label}
      >
        <Logo
          aria-label={header.logo.label}
          className={styles[`header__logo`]}
          name="smart-logo-wordmark"
          title={header.logo.label}
        />
      </a>
    </header>
  );
}

export default Header;

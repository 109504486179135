// core packages
import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet-async';
import {useNavigate} from 'react-router-dom';
import ErrorCodeNotificationDisplay from '../../components/ErrorCodeNotificationDisplay/ErrorCodeNotificationDisplay';
import Layout from '../../components/Layout/Layout';
import ScreenLoader from '../../components/ScreenLoader/ScreenLoader';

const gigyaApiKey = process.env.GIGYA_API_KEY;

const hasOidcContext = () => {
  const gigyaCache = JSON.parse(sessionStorage.getItem(`gigyaCache`) || `{}`);
  return !!gigyaCache?.[`gig_oidcContext_${gigyaApiKey}`]?.response?.[0]
    ?.context;
};

const hasDevUrlParam = () => {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  return !!params.get(`dev`);
};

export const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!hasDevUrlParam() && !hasOidcContext()) {
      console.warn(`no valid gigya OIDC context found - redirecting to /`);
      navigate(`/`, {replace: true});
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>smart Login</title>
        <meta name="description" content="smart Login" />
      </Helmet>
      <div className="login">
        <Layout>
          <ErrorCodeNotificationDisplay />
          <ScreenLoader screenSet="smart-RegistrationLogin" />
        </Layout>
      </div>
    </>
  );
};

export default Login;

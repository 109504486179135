import {Notification} from '@smart/web-components';
import type {SignalVariant} from '@smart/web-components/dist/types/components/types';
import Cookies from 'js-cookie';
import React, {useState} from 'react';
import type {
  ErrorCodeNotification,
  ErrorCodeNotifications,
} from '../../context/MarketConfigContext';
import {MarketConfigContext} from '../../context/MarketConfigContext';
import {loginErrorsCookieName} from '../../lambda/re-initiate-login/cookie-names';
import './ErrorCodeNotificationDisplay.scss';

const determineActiveErrorCode = (): string | undefined => {
  return determineErrorCodeFromUrl() || determineErrorCodeFromCookie();
};

const getCurrentSearchParams = (): URLSearchParams => {
  return new URL(window.location.href).searchParams;
};

const determineErrorCodeFromUrl = (): string | undefined => {
  const params = getCurrentSearchParams();

  return params.get(`errorCode`) || params.get(`code`) || undefined;
};

const determineAdditionalTextFromUrl = (): string | undefined => {
  const params = getCurrentSearchParams();

  return params.get(`message`)?.replace(/\+/g, ` `) || undefined;
};

const determineErrorCodeFromCookie = (): string | undefined => {
  return Cookies.get(loginErrorsCookieName);
};

const findNotificationForErrorCode = (
  notifications: ErrorCodeNotifications,
  errorCode?: string,
): ErrorCodeNotification | undefined => {
  return errorCode
    ? Object.values(notifications).find((value) => value.code === errorCode) ||
        Object.values(notifications).find((value) => value.code === `*`)
    : undefined;
};

function ErrorCodeNotificationDisplay() {
  const data = React.useContext(MarketConfigContext);

  const notifications = data.errorCodes;
  const [showNotification, setShowNotification] = useState(true);

  const errorCode = determineActiveErrorCode();

  const notificationForErrorCode = findNotificationForErrorCode(
    notifications,
    errorCode,
  );

  const additionalText = determineAdditionalTextFromUrl();

  const closeNotification = () => {
    setShowNotification(false);
  };

  return (
    <>
      {errorCode && notificationForErrorCode && showNotification && (
        <div className={`errorcodenotification`} data-error-code={errorCode}>
          <Notification
            className={`notification_width`}
            closeButton={{
              ariaLabel: `Close Notification`,
              onClick: closeNotification,
            }}
            label={notificationForErrorCode.headline}
            text={
              additionalText
                ? `${notificationForErrorCode.text} ${additionalText}`
                : notificationForErrorCode.text
            }
            variant={notificationForErrorCode.variant as SignalVariant}
          />
        </div>
      )}
    </>
  );
}

export default ErrorCodeNotificationDisplay;

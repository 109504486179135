import type {FeatureFlagPayload} from '@smart/utils';
import type {FetchCommonOptions} from './types';

interface FetchFeatureFlagsOptions extends FetchCommonOptions {}
export async function fetchFeatureFlags(
  options: FetchFeatureFlagsOptions,
): Promise<FeatureFlagPayload> {
  const {marketId, preview, signal} = options;

  const envName = process.env.ENV_NAME;

  if (!envName) {
    throw Error(`Missing "envName"`);
  }

  const searchParams = new URLSearchParams({
    envName,
    marketId,
    preview,
  });

  const url = `${
    process.env.BASE_BFF_URL
  }/feature-flags?${searchParams.toString()}`;
  const response = await fetch(url, {signal});

  if (!response.ok) {
    throw Error(`Could not fetch AEM content`);
  }

  return response.json() as Promise<FeatureFlagPayload>;
}

// core packages
import React from 'react';
import {Helmet} from 'react-helmet-async';
import Layout from '../../components/Layout/Layout';
import ScreenLoader from '../../components/ScreenLoader/ScreenLoader';

export const ChangeSmartId = () => {
  return (
    <>
      <Helmet>
        <title>Change Smart Id</title>
        <meta name="description" content="Change Smart Id" />
      </Helmet>
      <div className="change-smart-id">
        <Layout>
          <ScreenLoader
            screenSet="smart-ProfileUpdate"
            startScreen="gigya-update-profile-screen"
          />
        </Layout>
      </div>
    </>
  );
};

export default ChangeSmartId;

import {Footer as FooterComponent} from '@smart/web-components';
import React, {useEffect} from 'react';
import type {LabelLink} from '../../context/MarketConfigContext';
import {MarketConfigContext} from '../../context/MarketConfigContext';
import {parseFooterCopyrightYear} from '../../utils/parse-footer-copyright-year';

const parseLinkIdentifier = (hashString?: string) => {
  return hashString?.startsWith(`#smart:`)
    ? hashString.replace(`#smart:`, ``)
    : undefined;
};

function Footer() {
  const footer = React.useContext(MarketConfigContext);

  const findFooterLink = (hashString?: string): LabelLink | undefined => {
    const linkIdentifier = parseLinkIdentifier(hashString);
    return linkIdentifier ? footer.links[linkIdentifier] : undefined;
  };

  useEffect(() => {
    const link = findFooterLink(location.hash);

    if (link) {
      try {
        location.replace(link.href);
      } catch {
        location.assign(link.href);
      }
    }
  }, [footer]);

  useEffect(() => {
    const getClickedFooterLink = (event: any) => {
      const targetElement = event.target?.closest(`a[href^='#smart:']`);

      if (targetElement) {
        const link = findFooterLink(targetElement.getAttribute(`href`));

        if (link) {
          event.preventDefault();
          window.open(link.href, targetElement.getAttribute(`target`));
        }
      }
    };

    document.addEventListener(`click`, getClickedFooterLink);

    return () => {
      document.removeEventListener(`click`, getClickedFooterLink);
    };
  }, [footer]);

  const linkGroup3 = Object.values(footer.links)
    .filter((link) => !link.hidden)
    .map((value: any) => ({
      href: value.href,
      label: value.label,
      onClick: () => {},
    }));

  return (
    <FooterComponent
      copyText={parseFooterCopyrightYear(footer.copyright)}
      countryLink={{
        href: `${footer.footerLink?.href ? footer.footerLink.href : `#`}`,
        label: `${footer.footerLink?.label ? footer.footerLink?.label : ``}`,
        ariaLabel: `${
          footer.footerLink?.label ? footer.footerLink?.label : ``
        }`,
      }}
      linkGroup3={linkGroup3}
      logoLink={{
        href: footer.logo.href,
        label: footer.logo.label,
      }}
      logoSrc={`${process.env.BASE_URL}/assets/smart-logo-vertical.svg`}
      socialMediaNavigationItems={[]}
      socialMediaNavigationLabel=""
      termsAndStatements={[]}
    />
  );
}

export default Footer;
